@import '../../../variables.scss';
.contact-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
  color: #050713;
}

.break-line3 {
  border: 1.2px solid #004dff;
  width: 85px;
  margin-top: 2px;
  margin-bottom: 26px;
}

.contact-text {
  font-size: 15px;
  line-height: 1.73;
  color: #404246;
  margin: 0;
}

.contact-heading {
  font-size: 15px;
  line-height: 1.73;
  color: #404246;
  font-weight: bold;
  margin: 0;
}

.contact-content {
  width: 33%;
  padding-bottom: 20px;
  padding-top: 30px;
  @include sm {
    width: 100%;
  }
  @include tablets {
    width: 33%;
  }
}

.department-container {
  display: flex;
  flex-wrap: wrap;
  padding-top: 100px;
  padding-bottom: 100px;
  padding-right: 12%;
  padding-left: 12%;
  justify-content: center;
  @include sm {
    display: block;
    padding: 8%;
  }
  @include tablets {
    padding: 40px;
  }
}

.snip1376 {
  position: relative;
  overflow: hidden;
  margin: 30px;
  min-width: 230px;
  max-width: 250px;
  width: 100%;
  color: #141414;
  text-align: left;
  line-height: 1.4em;
  font-size: 16px;
  @include sm {
    margin: 0;
    max-width: 315px;
  }
}
.snip1376 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.snip1376 img {
  max-width: 100%;
  vertical-align: top;
}
.snip1376 figcaption {
  width: 100%;
  background-color: #ffffff;
  padding: 15px 25px 65px;
  position: relative;
}
.snip1376 figcaption:before {
  position: absolute;
  content: '';
  z-index: 2;
  bottom: 100%;
  left: 0;
  width: 100%;
  height: 80px;
  background-image: -webkit-linear-gradient(
    top,
    transparent 0%,
    #ffffff 100%
  );  
  background-image: linear-gradient(
    to bottom,
    transparent 0%,
    #ffffff 100%
  );
}
.snip1376 h2,
.snip1376 p {
  margin: 0 0 10px;
}
.snip1376 h2 {
  font-weight: 300;
  font-size: 1.5em;
  line-height: 1.2em;
  text-transform: uppercase;
}
.snip1376 p {
  font-size: 0.9em;
  letter-spacing: 1px;
  opacity: 0.9;
}

.placeholder {
  background-color: #d3d3d3;
  width: 100%;
  height: 375px;
  aspect-ratio: 1;
}
